.error-snackbar {
  position: fixed;
  z-index: 1400;
  transform: translateX(-50%);
  bottom: 60px !important;
  left: 50%;
}

@media (max-width: 1200px) {
  .error-snackbar {
    bottom: 250px !important;
  }
}