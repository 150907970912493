.ach-form__input {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.25rem;
}

.ach-form__tnc-checkbox::part(control) {
  margin-top: 0.5rem;
}

.ach-form__ctas {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
}

.ach-form__cancel-cta {
  margin-right: 0.5rem;
}

@media (max-width: 650px) {
  .ach-form__ctas {
    flex-direction: column;
    gap: 1.5rem;
  }

  .ach-form__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}