.credit-card-form {
  margin-top: 2rem;
  width: 100%;
}

.credit-card-form__exp-cvv {
  display: flex;
  margin: 1.5rem -1rem 0;
}

@media (max-width: 650px) {
  .credit-card-form__exp-cvv {
    flex-direction: column;
    gap: 1rem;
  }
}