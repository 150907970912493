.direct-debit-form {
  overflow: scroll;
}

.direct-debit-form__iban {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  gap: 1.25rem;
  grid-template-areas: 
  "input input input input input input input input input input btn btn";
}

.direct-debit-form__input {
  grid-area: input;
}

.direct-debit-form__cta {
  grid-area: btn;
}

.direct-debit-form__menu-separator {
  margin: 1.25rem 0 0.75rem;
}

@media (max-width: 650px) {
  .direct-debit-form__iban {
    margin-top: 2rem;
    grid-template-rows: 1fr auto;
    grid-template-areas: 
    "input input input input input input input input input input input input"
    ". . . . . . . . btn btn btn btn";
    gap: 1.5rem;
  }
}