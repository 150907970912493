.credit-card-add-pay-button {
  display: flex;
  margin-top: var(--goto-spacing-06);
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 650px) {
  .credit-card-add-pay-button {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .credit-card-add-pay-button__cta, .credit-card-add-pay-button__cta > chameleon-button {
    width: 100%;
  }
}