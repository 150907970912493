.pay-now {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  padding: 2rem;
  gap: 1.25rem;
  grid-template-areas:
  "nav nav hd hd hd hd hd hd hd hd logo logo"
  ". sd sd sd main main main main main main . ."
  "ft ft ft ft ft ft ft ft ft ft ft ft";
  overflow-y: scroll;
}

.pay-now__nav {
  grid-area: nav;
  display: flex;
  align-items: center;
}

.pay-now__header {
  grid-area: hd;
  display: flex;
  justify-content: space-between;
}

.pay-now__logo {
  grid-area: logo;
  display: flex;
  justify-content: flex-end;
}

.pay-now__aside {
  grid-area: sd;
  height: fit-content;
  margin: 1rem 0 0;
  padding: 1.5rem;
  background-color: var(--goto-background-color-default);
  border: 1px solid var(--goto-border-color-default);
  border-radius: var(--goto-radius-medium);
}

.pay-now__main {
  grid-area: main;
  height: fit-content;
  margin: 1rem 0 0 1.25rem;
  background-color: var(--goto-background-color-default);
  border: 1px solid var(--goto-border-color-default);
  border-width: 8px 1px 1px;
  border-radius: var(--goto-radius-medium);
  border-top-color: var(--goto-brand-color-admin-blue);
  padding: 1.5rem;
}

.pay-now__footer {
  grid-area: ft;
  width: 100%;
  border-top: 1px solid var(--goto-border-color-default);
  padding: 1.25rem 10rem;
  display: flex;
  justify-content: center;
  background-color: var(--goto-background-color-default);
}

.pay-now__skeleton {
  margin-top: 1.5rem;
  width: 100%;
}

@media (max-width: 1200px) {
  .pay-now {
    grid-template-rows: auto auto auto 1fr auto;
    grid-template-areas:
    "nav nav . . . . . . . . logo logo"
    "hd hd hd hd hd hd hd hd hd hd hd hd"
    "sd sd sd sd sd sd sd sd sd sd sd sd"
    "main main main main main main main main main main main main"
    "ft ft ft ft ft ft ft ft ft ft ft ft";
  }

  .pay-now__aside {
    padding: 1rem;
    margin: 0;
  }

  .pay-now__main {
    margin: 0;
    padding: 1rem;
  }

  .pay-now__footer {
    padding: 1rem 2rem;
    text-align: center;
  }
}

@media (max-width: 650px) {
  .pay-now__footer {
    padding: 1rem 0.5rem;
  }
}