.credit-card-save-button {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
}

.credit-card-save-button__cancel {
  margin-right: var(--goto-spacing-06);
}

@media (max-width: 650px) {
  .credit-card-save-button {
    flex-direction: column;
    gap: 1.5rem;
  }

  .credit-card-save-button__cta {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}