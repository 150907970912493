.price-info__list {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  grid-template-areas:
    "ref ref ref ref ref ref ref ref ref ref ref ref"
    "rec rec rec rec rec rec rec rec rec rec rec rec"
    "con con con con con con con con con con con con";
}

.price-info__reference {
  grid-area: ref;
}

.price-info__contact {
  grid-area: con;
}

.price-info__recipient {
  grid-area: rec;
}

.price-info__list > li {
  margin-bottom: 0.5rem;
}

.price-info-email {
  word-break: break-all;
}

.price-info__separater {
  margin: 1rem 0 0.5rem;
}

.price-info__total {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .price-info__list {
    grid-template-areas:
      "ref ref ref rec rec rec con con con con con con";
  }

  .price-info__reference {
    text-align: start;
  }

  .price-info__recipient {
    text-align: center;
  }

  .price-info__contact {
    text-align: end;
  }
}

@media (max-width: 650px) {
  .price-info__list {
    grid: none;
  }

  .price-info__reference, .price-info__recipient, .price-info__contact {
    text-align: start;
    word-break: break-all;
  }

  .price-info__recipient {
    margin: 0.5rem 0;
  }

  .price-info__reference::part(heading), .price-info__reference::part(icon), .price-info__reference::part(region)  {
    padding: 0;
    gap: 0;
  }
}