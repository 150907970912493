.payment-feedback {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  padding: 2rem;
  gap: 1.25rem;
  grid-template-areas:
  ". . . . . . . . . . logo logo"
  "main main main main main main main main main main main main"
  "ft ft ft ft ft ft ft ft ft ft ft ft";
  overflow-y: scroll;
}

.payment-feedback__logo {
  grid-area: logo;
}

.payment-feedback__loader {
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.payment-feedback__succeed {
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.payment-feedback__failure {
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.payment-feedback__footer {
  grid-area: ft;
  width: 100%;
  border-top: 1px solid var(--goto-border-color-default);
  padding: 1rem 0.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: var(--goto-background-color-default);
}
